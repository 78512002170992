// FleetLocate colors

// primary

$light-primary: #cb2b1d;
$light-primary-contrast: #ffffff;

$dark-primary: #cb2b1d;
$dark-primary-contrast: #ffffff;

// accent/secondary

$light-accent: #5dade2;
$light-accent-contrast: #ffffff;

$dark-accent: #5dade2;
$dark-accent-contrast: #ffffff;

// common

$page-background: #cb2b1d2f;
